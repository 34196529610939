import {
  AddGuestTrialInfoResponse,
  StartGuestTrialResponse,
} from "../types/api/ProxyKeyServiceTypes";
import f2aFetch from "../utils/fetch.utils";

/**
 * @description Service class to interact
 * with the Guest Trial API
 * @class GuestTrialService
 * @exports GuestTrialService
 *  */
export class GuestTrialService {
  /**
   * @param firstName - first name of the guest
   * @param email - email of the guest
   * @param mailingConsent - whether the guest has consented to mailing
   * @returns a response with the id of the guest entity created in the database
   * @description Add information about a person who has signed up for a trial and/or the mailing list to the database.
   *  */
  async addGuestTrialInfo(
    firstName: string,
    email: string,
    mailingConsent: boolean
  ) {
    let response = await f2aFetch("/guest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        email: email,
        mailingConsent: mailingConsent,
      }),
    });

    const data = await response.json();
    return data as AddGuestTrialInfoResponse;
  }

  /**
   *
   * @param email an email of the guest to start the trial for
   * @param hash a hash value generated by the backend to verify the email
   * @returns a response with the trial key and expiration date
   * @description Start a trial for a guest with the given email.
   */
  async startGuestTrial(email: string, hash: string) {
    let response = await f2aFetch("/Guest/Key", {
      method: "PUT",
      body: JSON.stringify({
        email: email,
        hash: hash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data as StartGuestTrialResponse;
  }

  async OptOut(email: string, hash: string): Promise<Response> {
    let response = await f2aFetch("/guest/OptOut", {
      method: "PUT",
      body: JSON.stringify({ email: email, hash: hash }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  }
}
