import RoutingPath from "../routes/routes";
import {
  MedicalInformationOutlined,
  HomeOutlined,
  HelpOutline,
  ArticleOutlined,
  Home,
  Article,
  MedicalInformation,
  Help,
  KitchenOutlined,
  PeopleAltOutlined,
  ListAltOutlined,
  CalendarTodayOutlined,
  CoffeeOutlined,
  EventAvailableOutlined,
  HandshakeOutlined,
  StorefrontOutlined,
  PlusOneOutlined,
  MinorCrashOutlined,
  ApartmentOutlined,
  FlightOutlined,
  LocalHospitalOutlined,
  RecordVoiceOverOutlined,
  Person2Outlined,
  MonitorHeartOutlined,
} from "@mui/icons-material";
import { envToBool } from "../helpers/env-helper";
export const NAV_DRAWER_WIDTH = 280;

export enum LinkCategory {
  Healthcare = "Healthcare",
  Finance = "Finance and Insurance",
  Customer = "Customer and Sales Support",
  Retail = "Retail & E-commerce",
  Hospitality = "Hospitality",
  None = "None",
}

export type Route = {
  path: string;
  icon: any;
  icon_dark: any;
  text: string;
  buttonTitle?: string;
  description?: string;
  category: LinkCategory;
  isHidden?: boolean;
  isDisabled?: boolean;
  order: number;
};

export const CategoriesOrdered = [
  LinkCategory.Healthcare,
  LinkCategory.Finance,
  LinkCategory.Customer,
  LinkCategory.Retail,
  LinkCategory.Hospitality,
];

export const SIDE_NAV_LINKS: Route[] = [
  {
    path: RoutingPath.DefaultPage,
    icon: HomeOutlined,
    icon_dark: Home,
    text: "Home",
    isHidden: true,
    category: LinkCategory.None,
    order: 0,
  },
  {
    path: RoutingPath.InvoicePage,
    icon: ArticleOutlined,
    icon_dark: Article,
    text: "Invoice",
    buttonTitle: "Add invoice",
    description: "Quickly add a new invoice to the system.",
    category: LinkCategory.Finance,
    order: 0,
  },
  {
    path: RoutingPath.PatientRegistration,
    icon: MedicalInformationOutlined,
    icon_dark: MedicalInformation,
    text: "Patient Registration",
    description: "Register a new patient and their details.",
    buttonTitle: "Register patient",
    category: LinkCategory.Healthcare,
    order: 0,
  },
  {
    path: RoutingPath.HelpdeskPage,
    icon: HelpOutline,
    icon_dark: Help,
    text: "Help Desk",
    buttonTitle: "Helpdesk",
    description: "Get assistance or report an issue.",
    category: LinkCategory.Customer,
    order: 0,
  },
  {
    path: RoutingPath.EcommercePage,
    icon: ListAltOutlined,
    icon_dark: ListAltOutlined,
    text: "Orders",
    buttonTitle: "Update orders",
    description: "Make changes to existing orders.",
    category: LinkCategory.Retail,
    order: 1,
  },
  {
    path: RoutingPath.KitchenViewPage,
    icon: KitchenOutlined,
    icon_dark: KitchenOutlined,
    text: "Kitchen",
    buttonTitle: "Kitchen View",
    description: "Simulate kitchen view.",
    category: LinkCategory.Hospitality,
    order: 1,
  },
  {
    path: RoutingPath.CustomerFilterPage,
    icon: PeopleAltOutlined,
    icon_dark: PeopleAltOutlined,
    text: "Customer Filter",
    description: "Get customer order details, search, and filter orders.",
    buttonTitle: "Customer Filter",
    category: LinkCategory.Retail,
    order: 0,
  },
  {
    path: RoutingPath.FrontdeskPage,
    icon: CoffeeOutlined,
    icon_dark: CoffeeOutlined,
    text: "Coffee Shop",
    isDisabled: envToBool(process.env.REACT_APP_IS_FRONTDESK_DISABLED),
    description: "Check out coffee shop frontdesk.",
    buttonTitle: "Front Desk",
    category: LinkCategory.Hospitality,
    order: 2,
  },
  {
    path: RoutingPath.ScheduleDemoPage,
    icon: CalendarTodayOutlined,
    icon_dark: CalendarTodayOutlined,
    text: "Schedule a Demo",
    buttonTitle: "Schedule a Demo",
    description: "Scheduling a demo by automating the process.",
    isDisabled: envToBool(process.env.REACT_APP_IS_SCHEDULE_DISABLED),
    category: LinkCategory.Customer,
    order: 1,
  },
  {
    path: RoutingPath.CheckInPage,
    icon: EventAvailableOutlined,
    icon_dark: EventAvailableOutlined,
    text: "Check In",
    isDisabled: envToBool(process.env.REACT_APP_IS_CHECKIN_DISABLED),
    description: "Checking in a new hotel resident.",
    buttonTitle: "Try Check-in",
    category: LinkCategory.Hospitality,
    order: 0,
  },
  {
    path: RoutingPath.AddDeal,
    icon: HandshakeOutlined,
    icon_dark: HandshakeOutlined,
    isDisabled: envToBool(process.env.REACT_APP_IS_DEAL_DISABLED),
    text: "Add Deal",
    description: "Create new deals.",
    buttonTitle: "Add Deal",
    category: LinkCategory.Customer,
    order: 2,
  },
  {
    path: RoutingPath.MerchantRegistration,
    icon: StorefrontOutlined,
    icon_dark: StorefrontOutlined,
    text: "Merchant Registration",
    isDisabled: envToBool(process.env.REACT_APP_IS_MERCHANT_DISABLED),
    description: "Multi-step merchant registration.",
    buttonTitle: "Merchant",
    category: LinkCategory.Finance,
    order: 1,
  },
  {
    path: RoutingPath.AddProduct,
    icon: PlusOneOutlined,
    icon_dark: PlusOneOutlined,
    text: "Add Product",
    buttonTitle: "Add Product",
    description: "Add product to registry.",
    isDisabled: envToBool(process.env.REACT_APP_IS_ADDPRODUCT_DISABLED),
    category: LinkCategory.Retail,
    order: 2,
  },
  {
    path: RoutingPath.CarAccidentPage,
    icon: MinorCrashOutlined,
    icon_dark: MinorCrashOutlined,
    text: "Car Accident Report",
    description: "Submit report.",
    buttonTitle: "Accident Report",
    isDisabled: envToBool(process.env.REACT_APP_IS_ACCIDENT_DISABLED),
    category: LinkCategory.Finance,
    order: 2,
  },
  {
    path: RoutingPath.HouseInspectionPage,
    icon: ApartmentOutlined,
    icon_dark: ApartmentOutlined,
    text: "House Inspection",
    description: "Submit house inspection report.",
    buttonTitle: "House Report",
    isDisabled: envToBool(process.env.REACT_APP_IS_APPARTMENT_DISABLED),
    category: LinkCategory.None,
    order: 1,
  },
  {
    path: RoutingPath.TravelReimbursementPage,
    icon: FlightOutlined,
    icon_dark: FlightOutlined,
    text: "Travel Reimbursement",
    description: "Submit travel reimbursement report.",
    buttonTitle: "Refund Travel",
    isDisabled: envToBool(process.env.REACT_APP_IS_TRAVEL_DISABLED),
    category: LinkCategory.Finance,
    order: 3,
  },
  {
    path: RoutingPath.SurgeryClaimPage,
    icon: LocalHospitalOutlined,
    icon_dark: LocalHospitalOutlined,
    text: "Surgery Claim",
    description: "Submit surgery claim report.",
    buttonTitle: "Surgery Claim",
    isDisabled: envToBool(process.env.REACT_APP_IS_SURGERY_CLAIM_DISABLED),
    category: LinkCategory.Finance,
    order: 4,
  },
  {
    path: RoutingPath.SpeechAssessmentPage,
    icon: RecordVoiceOverOutlined,
    icon_dark: RecordVoiceOverOutlined,
    text: "Speech Assessment",
    description: "Submit speech assessment report.",
    buttonTitle: "Assess speech",
    isDisabled: envToBool(process.env.REACT_APP_IS_SPEECH_ASSESSMENT_DISABLED),
    category: LinkCategory.Healthcare,
    order: 1,
  },
  {
    path: RoutingPath.NoSurgeryClaimPage,
    icon: Person2Outlined,
    icon_dark: Person2Outlined,
    text: "Treatment Claim",
    description: "Submit non-surgical treatment claim report.",
    buttonTitle: "Claim",
    isDisabled: envToBool(process.env.REACT_APP_IS_NO_SURGERY_CLAIM_DISABLED),
    category: LinkCategory.Finance,
    order: 5,
  },
  {
    path: RoutingPath.PatientLookupPage,
    icon: MonitorHeartOutlined,
    icon_dark: MonitorHeartOutlined,
    text: "Patient Lookup",
    description: "Search for a patient.",
    buttonTitle: "Patient Lookup",
    isDisabled: envToBool(process.env.REACT_APP_IS_PATIENT_LOOKUP_DISABLED),
    category: LinkCategory.Healthcare,
    order: 2,
  },
];
